import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
//constants
import Url from './constants/Url';
//screens
import Home from './screens/Home';
//components
import Header from './components/Header';
import Footer from './components/Footer';
//axios
import axios from 'axios';
  //ReduxToolkit
import { getAll } from './features/artists.slice';
import { allPartners } from './features/partners.slice';
import { allPhotos } from './features/photos.slice';
import { allExpositions } from './features/expositions.slice';
//Model
import Message from './models/message';

const App = () => {


  //ReduxToolkit
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [artistsData, setArtistsData] = useState([]);
  const [paintorsData, setPaintorsData] = useState([]);
  const [photographersData, setPhotographersData] = useState([]);
  const [sculptorsData, setSculptorsData] = useState([]);
  const [photosData, setPhotosData] = useState([]);
  const [partnersData, setPartnersData] = useState([]);
  const [exposData, setExposData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [oeuvresArtistSelected, setOeuvresArtistSelected] = useState(useSelector(state => state.artists.oeuvresArtistSelected));



  const loadArtists = async() =>{

    let allArtists = new Array();
    //console.log("** allArtists ** : "+allArtists.length);

  try {

    //console.log("loadArtist");

    let artists = [];
    await axios.get(`${Url.lo4art_Api}artist/all`)
    .then( res => artists = res.data.results )
    .catch(err => console.log(err));

    //console.log("artists : ");
    //console.log(artists.length);


    for (var i = 0; i < artists.length; i++) {
      console.log(artists[i]);
      let artist = artists[i];

      let nomFichier = "";
      await axios.get(`${Url.lo4art_Api}oeuvre/HomeDisplayByArtistId/${artist.id}`)
      .then( res => nomFichier = res.data.results[0].nom_fichier)
      .catch(err => console.log(err));

      //console.log("nom fichier : "+nomFichier);

      var nomLowerCase = artist.nom.toLowerCase().replaceAll(" ","%20");
      var nomFichierFormatted = nomFichier.replaceAll(" ","%20");
      var path=`${Url.lo4art_Cdm}oeuvres-artistes/${nomLowerCase}/${nomFichierFormatted}`;
      artist.nomFichier = path;
      console.log("** : "+nomLowerCase +" ** nomFichier url ** : "+artist.nomFichier);

      allArtists.push(artist);
      //console.log(allArtists.length);
    }

    dispatch(getAll(allArtists));
    setArtistsData(allArtists);
    setPaintorsData(allArtists.filter(a=>a.typologie===1));
    setPhotographersData(allArtists.filter(a=>a.typologie===2));
    setSculptorsData(allArtists.filter(a=>a.typologie===3));

    } catch (error) {
        console.log(JSON.stringify(error.message));
    }

}

  const loadPhotos = async() => {

    try {

      let photos = [];
      await axios.get(`${Url.lo4art_Api}photo/all`)
      .then( res => photos = res.data.results )
      .catch(err => console.log(err));


    console.log(" ********************************************* photos **********************************");
    console.log(photos);

        dispatch(allPhotos(photos));
        setPhotosData(photos);
    } catch (error) {
      console.log(JSON.stringify(error.message));
    }

  }

  const loadPartners = async() => {

    try {

      let partners = [];
      await axios.get(`${Url.lo4art_Api}partner/all`)
      .then( res => partners = res.data.results )
      .catch(err => console.log(err));


    console.log(" ********************************************* partners **********************************");
    console.log(partners);

        dispatch(allPartners(partners));
        setPartnersData(partners);
    } catch (error) {
      console.log(JSON.stringify(error.message));
    }

  }


  const loadExpos = async() => {

    try {

      let expos = [];
      await axios.get(`${Url.lo4art_Api}expo/all`)
      .then( res => expos = res.data.results )
      .catch(err => console.log(err));

      dispatch(allExpositions(expos));
      setExposData(expos);
    } catch (error) {
      console.log(JSON.stringify(error.message));
    }

  }




if(isLoading){

loadArtists();
loadPhotos();
loadPartners();
loadExpos();
  
  setIsLoading(false);
}



  return (
    <div id="page">
    <Router>
    <Header/>

          <Switch>
            <Route exact path="/" >
            <Home isLoading={isLoading} page="Home" data={artistsData} />
            </Route>
            <Route exact path="/Peintres" >
            <Home isLoading={isLoading} page="Peintres" data={paintorsData} />
            </Route>
            <Route exact path="/Photographes" >
            <Home isLoading={isLoading} page="Photographes" data={photographersData} />
            </Route>
            <Route exact path="/Sculpteurs" >
            <Home isLoading={isLoading} page="Sculpteurs" data={sculptorsData} />
            </Route>
            <Route exact path="/Galerie" >
            <Home isLoading={isLoading} page="Galerie" data={partnersData} title="À propos"/>
            </Route>
            <Route exact path="/PhotosExpos" >
            <Home isLoading={isLoading} page="PhotosExpos" data={photosData} title="Photos d'Expositions"/>
            </Route>
            <Route exact path="/Expositions" >
            <Home isLoading={isLoading} page="Expositions" data={exposData} title="Expositions"/>
            </Route>
            <Route exact path="/Contact" >
            <Home isLoading={isLoading} page="Contact" title="Contact"/>
            </Route>
            <Route exact path="/Oeuvres" >
            <Home isLoading={isLoading} page="Oeuvres" data={oeuvresArtistSelected}/>
            </Route>
          </Switch>

    <Footer/>
    </Router>

    </div>
  );
};

export default App;
